import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.organizations.length)?_c(VDialog,{attrs:{"max-width":"400","persistent":"","transition":"dialog-top-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v(" View Parcels For "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('div',[_c(VBtn,{staticClass:"justify-start text-capitalize",class:{ 'v-btn--active': _vm.loggedInAs.id === _vm.user.uid },attrs:{"color":"primary","text":"","block":"","large":""},on:{"click":function($event){_vm.loginAsUser(true);
            _vm.close();}}},[_vm._v(" "+_vm._s(_vm.user.profile.first_name + ' ' + _vm.user.profile.last_name)+" ")]),_vm._l((_vm.organizations),function(org){return _c(VBtn,{key:org.id,staticClass:"justify-start text-capitalize my-2",class:{ 'v-btn--active': _vm.loggedInAs.id === org.id },attrs:{"text":"","color":"primary","block":"","large":""},on:{"click":function($event){_vm.loginAsOrganization(org.id, true);
            _vm.close();}}},[_vm._v(" "+_vm._s(org.name)+" ")])})],2)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }