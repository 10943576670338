<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
    persistent
    transition="dialog-top-transition"
    v-if="organizations.length"
  >
    <v-card class="mx-auto">
      <v-card-title>
        View Parcels For
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div>
          <v-btn
            color="primary"
            text
            class="justify-start text-capitalize"
            :class="{ 'v-btn--active': loggedInAs.id === user.uid }"
            block
            large
            @click="
              loginAsUser(true);
              close();
            "
          >
            {{ user.profile.first_name + ' ' + user.profile.last_name }}
          </v-btn>
          <v-btn
            text
            color="primary"
            class="justify-start text-capitalize my-2"
            block
            :class="{ 'v-btn--active': loggedInAs.id === org.id }"
            large
            v-for="org in organizations"
            :key="org.id"
            @click="
              loginAsOrganization(org.id, true);
              close();
            "
          >
            {{ org.name }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import accMixin from '../mixins/account';
export default {
  mixins: [accMixin],
  computed: {
    ...mapState(['organizations', 'loggedInAs', 'user']),
    user_profile() {
      if (this.$store.getters.user.profile.first_name) {
        return this.$store.getters.user.profile;
      }
      return null;
    }
  },
  data() {
    return {
      dialog: true
    };
  },
  methods: {
    close() {
      localStorage.setItem('selectAccount', true);
      this.dialog = false;
    }
  }
};
</script>

<style>
</style>
